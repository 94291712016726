<template>
  <div class="user-app-view-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Trở về trước
        </b-button>
        <template v-if="isWritePermission">
          <b-button
            class="btn btn-inactive ml-3"
            type="button"
            v-if="userData.active === true"
            @click.prevent="inactive(userData)"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
            </span>
            Inactive
          </b-button>
          <b-button
            class="btn btn-active ml-3"
            type="button"
            @click.prevent="inactive(userData)"
            v-if="userData.active === false"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
            </span>
            Active
          </b-button>
        </template>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-3"
          type="button"
          @click.prevent="editItem(userData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
          </span>
          Chỉnh sửa
        </b-button>
        <b-button class="btn btn-success ml-3" type="button" @click="goToZalo">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Chatting/comment.svg" />
          </span>
          Nhắn tin
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container fluid class="user-app-view-page__body mb-6 mt-6">
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b user-info-card mb-0 h-100">
              <div class="card-body mt-0">
                <b-row>
                  <b-col>
                    <div class="d-flex align-items-center">
                      <avatar
                        size="100px"
                        :text="userData.fullName"
                        :src="userData.imageUrl && userData.imageUrl.url"
                        :rounded="true"
                      ></avatar>
                      <div class="d-flex flex-column ml-5">
                        <h5 style="color: #515356">
                          {{ userData.fullName }}
                        </h5>
                        <p
                          class="mt-2 mb-0"
                          style="font-size: 12px; color: #888c9f"
                        >
                          Mã số: {{ userData.code || ''
                          }}{{
                            userData.ownPackage
                              ? `, ${userData.ownPackage.name}`
                              : ', Thành viên Cơ Bản'
                          }}
                        </p>
                        <div class="d-flex align-items-center mt-2">
                          <span
                            class="btn btn-active"
                            style="width: 85px; cursor: default"
                            v-if="userData.active"
                          >
                            Active
                          </span>
                          <span
                            class="btn btn-inactive"
                            style="width: 85px; cursor: default"
                            v-else
                          >
                            Inactive
                          </span>
                          <span
                            class="btn btn-user-app ml-3"
                            style="width: 85px; cursor: default"
                          >
                            App User
                          </span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-8">
                  <b-col>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Tuổi:</div>
                        <div>{{ userData.age }}</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Điện thoại:</div>
                        <div>
                          {{ userData.phoneNumber }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Điện thoại thay thế:</div>
                        <div>
                          {{
                            userData.secondPhoneNumber &&
                            userData.secondPhoneNumber.replace('+84', 0)
                          }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Email:</div>
                        <div>{{ userData.email }}</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Tỉnh thành:</div>
                        <div>
                          {{ userData.province && userData.province.name }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Phường / Xã:</div>
                        <div>
                          {{ userData.ward && userData.ward.name }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Ngày sinh:</div>
                        <div>
                          {{
                            userData.dateOfBirth > 0
                              ? $moment(userData.dateOfBirth * 1000).format(
                                  'DD/MM/YYYY',
                                )
                              : null
                          }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Giới tính:</div>
                        <div>
                          <user-gender :gender="userData.gender" />
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Quốc gia:</div>
                        <div>{{ userData.nation && userData.nation.name }}</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Quận / Huyện:</div>
                        {{ userData.district && userData.district.name }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Địa chỉ:</div>
                        <div>{{ userData.address }}</div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col class="d-grid">
            <b-row>
              <b-col>
                <div
                  class="card card-custom gutter-b user-patient-condition-card mb-0 h-100"
                >
                  <div class="card-header h-25">
                    <div class="card-title">
                      <h5 class="card-label text-success">Tình Trạng Bệnh</h5>
                    </div>
                  </div>
                  <div class="card-body mt-0">
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col>
                            <div class="text-muted">
                              Tình trạng bệnh tiểu đường:
                            </div>
                            <div>
                              {{ userData.diabetes && userData.diabetes.name }}
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-muted">Chiều cao:</div>
                            <div
                              v-if="
                                userData.height !== null &&
                                userData.height !== ''
                              "
                            >
                              {{ userData.height }}cm
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col>
                            <div class="text-muted">
                              Thời gian phát hiện bệnh:
                            </div>
                            <div v-if="userData.diabetes">
                              {{
                                $moment(userData.diabetes.date * 1000).format(
                                  'YYYY',
                                )
                              }}
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-muted">Cân nặng:</div>
                            <div
                              v-if="
                                userData.weight !== null &&
                                userData.weight !== ''
                              "
                            >
                              {{ userData.weight }}kg
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col>
                            <div class="text-muted">Bệnh viện:</div>
                            <div>{{ userData.hospital || '...' }}</div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  class="card card-custom gutter-b user-time-update-card mb-0 h-100"
                >
                  <div class="card-header h-25">
                    <div class="card-title">
                      <h5 class="card-label text-success">
                        Thời gian cập nhật
                      </h5>
                    </div>
                  </div>
                  <div class="card-body mt-0">
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col>
                            <div class="text-muted">Thời gian tạo:</div>
                            <div>
                              {{
                                $moment(userData.createDatetime * 1000).format(
                                  'DD/MM/YYYY',
                                )
                              }}
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-muted">Người tạo:</div>
                            <div class="d-flex align-items-center mt-2">
                              <avatar
                                size="40px"
                                :text="userData.creator"
                                :src="null"
                                :rounded="true"
                              ></avatar>
                              <div class="d-flex flex-column ml-5">
                                <p
                                  class="mb-0"
                                  style="
                                    font-weight: 600;
                                    font-size: 13px;
                                    color: #515356;
                                  "
                                >
                                  {{ userData.creator }}
                                </p>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col>
                            <div class="text-muted">
                              Lần cập nhật cuối cùng:
                            </div>
                            <div>
                              {{
                                $moment(userData.updatedDate).format(
                                  'DD/MM/YYYY',
                                )
                              }}
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-muted">
                              Người cập nhật lần cuối:
                            </div>
                            <div class="d-flex align-items-center mt-2">
                              <avatar
                                size="40px"
                                :text="userData.updater"
                                :src="null"
                                :rounded="true"
                              ></avatar>
                              <div class="d-flex flex-column ml-5">
                                <p
                                  class="mb-0"
                                  style="
                                    font-weight: 600;
                                    font-size: 13px;
                                    color: #515356;
                                  "
                                >
                                  {{ userData.updater }}
                                </p>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
          class="card card-custom gutter-b user-patient-condition-card mb-0 h-100 mt-7"
        >
          <div class="card-header h-25">
            <div class="card-title">
              <h5 class="card-label text-success">Lịch sử mua gói</h5>
            </div>
          </div>
          <div class="card-body mt-0">
            <template-table
              :data="boughtPackages"
              :column="headers"
              :selectAction="false"
              :tableAction="false"
              :searchAction="false"
              :pagingAction="false"
            >
              <template v-slot:body="{ item }">
                <td>{{ item.name }}</td>
                <td>
                  {{ convertTimestampToDate(item.activationDate) }}
                </td>
                <td>{{ convertTimestampToDate(item.activationDate) }}</td>
                <td>
                  {{ convertTimestampToDate(item.expirationDate) }}
                </td>
              </template>
            </template-table>
          </div>
        </div>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import templateTable from '../../../components/table/template-table.vue';
export default {
  components: { templateTable },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFirstLoad: false,
      userData: {},
      loading: false,
      headers: [
        {
          label: 'Gói đã mua',
          class: 'text-left',
          key: 'package',
        },
        {
          label: 'Thời gian mua gói',
          class: 'text-left',
          key: 'buyDate',
        },
        {
          label: 'Thời gian bắt đầu',
          class: 'text-left',
          key: 'startDate',
        },
        {
          label: 'Thời gian kết thúc',
          class: 'text-left',
          key: 'endDate',
        },
      ],
      boughtPackages: [],
    };
  },
  computed: {
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  methods: {
    goToZalo() {
      window.open(this.userData.zaloUrl);
    },
    editItem(item) {
      this.$router.push({
        name: 'user_app_update',
        params: {
          form_type: 'edit',
          id: item.id,
        },
      });
    },
    returnPage() {
      this.$router.push({
        name: 'user_app_list',
      });
    },
    inactive(item) {
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn ${
          item.active ? 'inactive' : 'active'
        } app user ${item.fullName} (mã số ${item.code}) không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary rounded',
            closeModal: true,
          },
          confirm: {
            text: `${item.active ? 'Inactive' : 'Active'}`,
            value: true,
            visible: true,
            className: `btn btn-${item.active ? 'inactive' : 'active'} rounded`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.inactiveItem(item)
              .then(() => {
                this.$swal(
                  'Thành công!',
                  'Thay đổi trạng thái thành công.',
                  'success',
                );
                this.loadUserData();
              })
              .catch(() => {
                this.$swal('Lỗi!', 'Đã có lỗi xảy ra.', 'error');
              });
          }
        }.bind(this),
      );
    },
    async inactiveItem(item) {
      if (item.state !== 0) {
        let formData = new FormData();
        formData.append('patientId', item.id);
        formData.append('active', !item.active);
        await this.$api.put(`Patient/Input`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      return true;
    },
    async loadUserData() {
      this.loading = true;
      try {
        const { data } = await this.$api.get(
          `Patient/${this.$route.params.id}`,
        );
        await this.loadUserPackage(data.accountId);
        this.userData = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async loadUserPackage(id) {
      try {
        const { data } = await this.$api.get(`/PackageAccountHistory/GetPage`, {
          params: {
            accountId: id,
          },
        });
        this.boughtPackages = data.items || [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      }
    },
  },
  created() {
    this.loadUserData();
  },
};
</script>

<style lang="scss" scoped>
.d-grid {
  display: grid;
}
</style>
